<template>

  <div class="noborder" :style="whiteContainer">

    <Button type="button" @click="action()" :style="customButtons" class="buttonpattern">
          
      <div class="wrapper" style="text-align: left; padding-left: 18px;">
        <span :class="{colorP: showText}" class="faqtext">{{label}}</span>
          
        <span  class="faqicon"> 
          <i class="pi pi-angle-down" v-if="!showText" :class="{colorPIcon: !showText}"></i>
          <i class="pi pi-angle-up"  v-if="showText" :class="{colorPIcon: showText}"></i>
        </span>

      </div>

      <div v-show="showText" class="hiddentext faqHtml">
        <span v-html="text"></span>
      </div>

    </Button>

  </div>
  
</template>

<script>
export default {
  data() {
    return {
      whiteContainer: {},
      customButtons:{},
      showText:false
    }
  },
  props:{
    label: {
      type: String,
      required: true
    },
    text:{
      type:String,
      required:true
    }
  },
  created() {
    this.whiteContainer = {
      'background': 'var(--page-background-color)',
      'box-shadow': 'var(--faq-box-shadow)',
      'border-radius': '8px',
    },
    this.customButtons={
      backgroundColor:'inherit',
      border: '0px',
      'font-style': 'normal',
      'font-weight': 'normal'
    }
  },
  methods:{
    action(){
      if(this.showText){
        this.showText = false;
      }
      else{
        this.showText = true;
      }
    }
  }
}
</script>

<style scoped>

.buttonpattern{
  border-radius: 8px;
  display: block;
  padding-top: 9px;
  padding-bottom: 16px;
  width: 100%;
}
.hiddentext{
  color: var(--faq-text-color);
  font-size: 14px;
  font-weight: var(--faq-text-weight);
  line-height: 24px;
  text-align: left;
  padding-left: 18px;
  padding-top: 24px;
}
.faqtext{
  color: var(--faq-unselected-title-color);
  font-weight: var(--faq-unselected-title-weight);
}
.colorP{
  color: var(--faq-selected-title-color);
  font-weight: var(--faq-selected-title-weight);
}
.colorPIcon{
  color: var(--faq-arrow-color);
}
.buttonpattern:enabled, .buttonpattern:disabled {
  box-shadow: none !important;
}
.wrapper{
  display: block;
}
.wrapper span{
  display: inline-block;
  height: 100%;
}
.wrapper span{
  display: inline-block;
  vertical-align: middle;
}
.wrapper .faqtext{
  width: 90%;
}
.wrapper .faqicon{
  text-align: right;
  width: 10%;
}

</style>