<template>

  <div class="page">
    <div class="margin-page">

      <div id="d1" style="display:none;z-index: 1000;position: relative;">
        <ModalAlwaysShow
          tipo='error'
          :messageErr="internalizationFaq.faqErrTimeOutLabel"
          :detailErr="internalizationFaq.faqErrTimeOutMsg"
        />
      </div>

      <div class="titlecss">
        {{internalizationFaq.faqTitle}}
      </div>

      <div class="separator"/>
      <LineSeparator/>

      <div v-for="faq in  faqObj" :key="faq.description">
        <div class="separator"/>
        <faq :label="faq.title" :text="faq.description"/>
      </div>
        
    </div>

    <ExceptionModal
      ref="exceptionWarnig"
      tipo='error'
      :messageErr="internalizationFaq.faqErrFetchLabel"
      :detailErr="internalizationFaq.faqErrFetchMsg"
    />
    <ExceptionModal
      ref="exceptionWarnig2"
      tipo='error'
      :messageErr="internalizationFaq.faqErrCommunicationFailLabel"
      :detailErr="internalizationFaq.faqErrCommunicationFailMsg"
    />
  </div>
   
</template>

<script>
import WhiteContainerFAQ from '../components/shared/WhiteContainerFAQ.vue';
import { getWithExpiry, getInternalization, endSess } from '@/util/util.js';

/* Internalization, used if not use BD to get data 
var internalizationFaqTmp = {

  "faqTitle":"Dúvidas Frequentes",

  "faqErrTimeOutLabel": "Tempo Excedido",
  "faqErrTimeOutMsg": "Você atingiu o limite de 05 minutos sem executar nenhuma ação.",

  "faqErrFetchLabel": "Falha na comunicação.",
  "faqErrFetchMsg": "Houve falha na comunicação. Tente novamente mais tarde.",

  "faqErrCommunicationFailLabel": "Falha na comunicação.",
  "faqErrCommunicationFailMsg": "Houve falha na comunicação. Tente novamente mais tarde.",

};
/* */

// used to ordenate an array of objects
function compareToSort(a, b){
  return a.position - b.position;
}

export default {

  data()  {
    return {
      faqObj:[],
      onLine:true,
    }
  },
  components:{
    'faq':WhiteContainerFAQ,
  },
  methods:{
    checkOnline(){
      if(!navigator.onLine){
        endSess('dados');
        this.$refs.exceptionWarnig2.enable();
      }
    },
    defTimeOut(){
      var timeout;

      function refresh(){

        clearTimeout(timeout);
        timeout = setTimeout(() => {
          var el = document.getElementById("d1");
          el.style.display = "flex";
        },  300000);
      }
      refresh();
      document.addEventListener('click', refresh);
    }
  },
  created(){

    this.internalizationFaq = getInternalization();
     
    const holder = JSON.parse(getWithExpiry('dados'))
    if(!holder){
      //this.$root.$refs.loadingModal.disable();
      //this.showWarningModal()
      this.$root.$refs.exceptionWarnigFetchingData.enable();
    }
    else{
      setInterval(() => {
        this.checkOnline();
      }, 3000);  
      this.defTimeOut();
    
      const FETCH_TIMEOUT = 30000;
      let didTimeOut = false;
      var urlReq = process.env.VUE_APP_CONSENTS+"/consent/faq";
    
      const promise = new Promise(function(resolve, reject){

        const timeout = setTimeout(function(){
          didTimeOut = true;
          reject(new Error('Request timed out'));
        }, FETCH_TIMEOUT);
        
        fetch(urlReq)
        .then(function(response) {
          // Clear the timeout as cleanup
          clearTimeout(timeout);
          if(!didTimeOut) {

            resolve(response.text());
              
          }
        })
        .catch(function(err) {
            
          // Rejection already happened with setTimeout
          if(didTimeOut) return;
          // Reject with error
          reject(err);
          //console.log(err);
        });
      });

      promise.then((response)=> {
        
        //console.log(response);
        var resp = JSON.parse(response);
        //console.log(resp);

        /*//if error in mongoDB
        var resp = [];

        //eslint-disable-next-line
        let responseTmp = response.replace(/\\\"/g,'').split("{");

        responseTmp.forEach(elem => {

            if(elem.includes('title')){

              let tmp = {
                title: '',
                description: ''
              }

              tmp.title = elem.split("title: ")[1].split(", description:")[0];
              tmp.description = elem.split("description: ")[1].split("}")[0];
              
              resp.push(tmp);
            }
          }
        );
        */
        
        resp.sort(compareToSort);

        resp.forEach(element => {

          let obj = {
            title:element.title,
            description:element.description
          }
          this.faqObj.push(obj);
        });
      })
      .catch((err) =>{
          // Error: response error, request timeout or runtime error
          this.$root.$refs.loadingModal.disable();
          this.$refs.exceptionWarnig.enable();
          //this.showWarningModal();
          //console.log(err);
      });
    }
  },
  mounted(){
    this.$root.$refs.mainNavbar.showGoBackButton();
  } 
}
</script>

<style scoped>
.page{
  background-color: var(--page-background-color);
}
.margin-page{
  padding-left: 11px;
  padding-right: 18px;
  padding-top: 10px
}
.titlecss{
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  padding-left: 13px;
  color: var(--faq-main-title-color);
}
.separator{
  padding-bottom: 16px;
}
</style>          